import React from "react"
import { Link } from "gatsby"
import styles from "./Layout.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons"

export default function Layout({ children }) {
  return (
    <div className={styles.siteWrapper}>
      <header className={styles.header}>
        <h1>
          <Link to="/">Black Friday Trailrun</Link>
        </h1>
        <Menu />
      </header>
      {children}
    </div>
  )
}

function Menu() {
  const options = [
    { name: "Hem", to: "/" },
    { name: "Loppet", to: "/loppet" },
    { name: "Banan", to: "/banan" },
    { name: "Anmälan", to: "/anmalan" },
    // { name: "Startlista", to: "/startlista" },
    { name: "Resultat", to: "/resultat/2023" },
  ]
  return (
    <nav className={styles.menu}>
      <input type="checkbox" id="menu-toggle" />
      <label htmlFor="menu-toggle">
        <span>&times;</span>
        <span>
          <FontAwesomeIcon icon={faAngleDoubleDown} />
        </span>
      </label>
      <ul>
        {options.map(o => (
          <li key={o.to}>
            <Link
              to={o.to}
              activeClassName={styles.current}
              className={styles.menuLink}
            >
              {o.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}
